<template>
  <div class="external-wrapper flex flex-stretch gap-2xl">
    <div
      :class="{ active: menuExpanded }"
      class="container side-menu-container container-s padding-remove">
      <div
        class="nav-inner-wrapper radius-s padding-m padding-l-top bg-bg-2 flex-column relative"
        style="height: 100%; height: calc(100vh - 120px)">
        <div
          class="logo flex flex-center flex-middle  margin-l-bottom cursor-pointer"
          @click="
            $router.push({
              name: isVendor ? 'user-dashboard-vendor-profile' : 'user-dashboard-profile',
            })
          ">
          <img
            :src="getDynamicAssetUrl('images/logos/main.png')"
            style="height: 50px; width: 50px" />
        </div>
        <base-side-nav
          v-if="sideNavLinks"
          class="side-nav"
          style="height: 100%; max-height: 100%"
          :items="sideNavLinks"></base-side-nav>
        <spinner v-else overlay="absolute" size="4"></spinner>
        <div class="menu-control cursor-pointer l:hidden z-level-2" @click="toggleMenu()">
          <icon-halo
            v-if="menuExpanded"
            size="2"
            halo-theme="lead"
            theme="bg"
            class="animate__pulse animate--fast border-bg-2 border-radius-round"
            :icon="'chevron-inline-start'"></icon-halo>
          <icon-halo
            v-else
            size="2"
            halo-theme="lead"
            theme="bg"
            class="animate__pulse animate--fast border-bg-2 border-radius-round"
            :icon="'align-justify-alt'">
          </icon-halo>
        </div>
      </div>
    </div>

    <div
      class="content-area width-expand container padding-remove-horizontal padding-xl-vertical">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import BaseSideNav from "@/client/components/nav/BaseSideNav.vue";

export default {
  components: {
    BaseSideNav,
  },
  middleware: {
    requireUser: {},
  },
  data: function () {
    return {
      menuExpanded: false,
      sideNavLinks: false,
    };
  },
  async created() {
    let navFactory = await this.getDynamicAsset(`core/nav/userDashboardNavFactory.js`);
    let getter = typeof navFactory === "function" ? navFactory : navFactory.default;
    this.sideNavLinks = await getter({
      asyncOperations: this.asyncOps,
      store: this.$store,
    });
  },
  methods: {
    closeMenu() {
      this.menuExpanded = false;
    },
    openMenu() {
      this.menuExpanded = true;
    },
    toggleMenu() {
      this.menuExpanded ? this.closeMenu() : this.openMenu();
    },
  },
  computed: {
    isVendor() {
      return !!this.$store.getters["user/profile"]?.Vendor;
    },
  },
};
</script>

<style scoped lang="scss">
.external-wrapper {
  --pd-menu-width: 200px;

  padding-inline: var(--container-gutter);
  @media (max-width: 1024px) {
    padding-inline-start: 0;
  }
}

.menu-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  inset-inline-start: 100%;
}

.side-menu-container {
  position: relative;
  width: var(--pd-menu-width);
  max-width: var(--pd-menu-width);
  z-index: var(--z-2);

  @media (max-width: 1024px) {
    width: 0;
    max-width: 0;

    .nav-inner-wrapper {
      position: absolute;
      width: var(--pd-menu-width);
      max-width: var(--pd-menu-width);
      top: 0;
      height: 100%;
      max-height: 100%;
      inset-inline-start: calc(-1 * var(--pd-menu-width) + 25px);
      min-height: 100%;
      transition: inset 175ms ease-in;
      border-radius: 0 !important;
      border-inline-end: 2px solid var(--c-lead);
      box-shadow: var(--box-shadow-5);

      .side-nav {
        opacity: 0;
        transition: opacity 200ms ease-in 50ms;
      }
    }

    &.active {
      .nav-inner-wrapper {
        margin-inline-start: 0;
        opacity: 1;
        pointer-events: auto;
        inset-inline-start: 0;
        .side-nav {
          opacity: 1;
        }
      }
    }
  }
  /*
@media (max-width: 1024px) {
  width: 250px;
  max-width: 250px;
  margin-inline-start: calc(-250px + 10px);
  transition: 200ms ease;

}

&.active {
  margin-inline-start: 0;
  opacity: 1;
}

 */
}
</style>
